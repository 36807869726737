<template>
	<footer id="share">

		<ul class="show parent">



			<li class="share-opener" :class="{ 'open' : nav.share }"  v-on:click="toggleNav('share')">
				<ul class="left" :class="{ 'show' : nav.share }">
					<li>
						<a 
							:href="'https://www.facebook.com/sharer/sharer.php?u=' + url"
							target="_blank"
						>
							<span class="icon-facebook"></span>
						</a>
					</li>
					<li>
						<a 
							:href="'https://twitter.com/share?url=' + url"
							target="_blank"
						>
							<span class="icon-twitter"></span>
						</a>
					</li>
					<li>
						<Copy :url="url"></Copy>
					</li>
				</ul>
			</li>

			<li class="social-opener" :class="{ 'open' : nav.link }"  v-on:click="toggleNav('link')">
				<ul class="left" :class="{ 'show' : nav.link }">
					<li>
						<a 
							href="https://ChrisBrown.lnk.to/followFI​"
							target="_blank"
						>
							<span class="icon-facebook"></span>
						</a>
					</li>
					<li>
						<a 
							href="https://ChrisBrown.lnk.to/followTI"
							target="_blank"
						>
							<span class="icon-twitter"></span>
						</a>
					</li>
					<li>
						<a 
							href="https://ChrisBrown.lnk.to/followII"
							target="_blank"
						>
							<span class="icon-instagram"></span>
						</a>
					</li>
					<li>
						<a 
							href="https://ChrisBrown.lnk.to/followWI"
							target="_blank"
						>
							<span class="icon-sphere"></span>
						</a>
					</li>
					<li>
						<a 
							href="https://ChrisBrown.lnk.to/followSI"
							target="_blank"
						>
							<span class="icon-spotify"></span>
						</a>
					</li>
				</ul>
			</li>

		</ul>
		
	</footer>
</template>
<script>
import Copy from '@/components/Copy'

export default {
	name: 'Share',
	components: {
		Copy
	},
	props : [],
	data() {
		return {
			nav : {
				share : false,
				link : false,
			},
			url: 'http://halloffame.chrisbrownworld.com'
		}
	},
	mounted: function() {

  },
	methods: {
		toggleNav( type ){
			this.nav[type] = !this.nav[type];
		}
	}
}
</script>
