<template>
	<div class="intro-container">
		<div class="wrap">
			<slot></slot>
		</div>
	</div>
</template>
<script>
	import Button from '@/components/Button'
	export default {
		name: 'Intro',
		props : [],
		components: {
        	Button,
        },
		data() {
			return {

			}
		},
	}
</script>
