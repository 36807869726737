<template>
	<div class="title-container" :class="{hero : hero, finished : finished}">
		<h2 :class="{accent : hero}" ><slot></slot></h2>
		<p v-if="albums">{{albums}} album{{albums != 1 ? 's' : ''}}</p>
		<p v-if="singles">{{singles}} single{{singles != 1 ? 's' : ''}}</p>
		<p v-if="awards">{{awards}} award{{awards != 1 ? 's' : ''}}</p>
		<p v-if="wins">{{wins}} win{{wins != 1 ? 's' : ''}}</p>
		<p v-if="nominations">{{nominations}} nomination{{nominations != 1 ? 's' : ''}}</p>
		<p v-if="custom">{{custom}}</p>
	</div>
</template>
<script>
	/*
		TO DO:
		* style it up
		* animate the numbers from 0
	*/
	export default {
		name: 'Title',
		props : [ 'hero', 'finished', 'albums', 'singles', 'awards', 'nominations', 'wins', 'custom' ],
		data() {
			return {

			}
		},
	}
</script>
