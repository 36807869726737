<template>
	<div :class="[
			'col-xs-12',
			'col-sm-' + size,
			center ? 'center' : '',
			offset ? 'off-sm-' + offset : '',
			
	    ]">
	    <div v-if="framed" class="framed"><slot></slot></div>
	    <slot v-else></slot>
	</div>
</template>
<script>
export default {
	name: 'Column',
	props : {
		size : {
	        type: Number,
	        default: 12
	    },
	    offset : {
	        type: Number,
	        default: 12
	    },
	    center : {
	        type: Boolean,
	        default: false
	    },
	    framed : {
	        type: Boolean,
	        default: false
	    }
    },
	data() {
		return {

		}
	},
}
</script>
