<template>
  <div class="copy" v-clipboard="url" v-clipboard:success="clipboardSuccessHandler">
    <div class="message">Link copied</div>
    <span class="icon-copy-down"></span>
  </div>
</template>
<script>
	/*
		TO DO:
		* allow different backgrounds
	*/
	export default {
		name: 'Copy',
    props : ['url'],
    methods : {
      clipboardSuccessHandler ({ value, event }) {
        let message = this.$el.getElementsByClassName("message")[0];
        message.style.opacity="1";
        setTimeout(() => {
          message.style.opacity="0";
        }, 5000);
      },
    },
	}
</script>
