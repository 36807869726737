<template>
	<div class="badge-container" :class="{wild:wild}">
		<div class="wrap">
			<p class="number white" v-if="rank">#{{rank}}</p>
			<p class="label black"><slot></slot></p>
		</div>
	</div>
</template>
<script>
export default {
	name: 'Badge',
	props : ['rank', 'wild'],
	data() {
		return {

		}
	},
}
</script>
