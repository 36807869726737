<template>
	<header id="header" v-on:click.prevent="onClick">

		<div>
			<h2 alt="previous" class="accent">{{$store.getters.year}}</h2>
			<h1 alt="navigation" class="accent">HALL OF FAME</h1>
			<h2 alt="next" class="accent">{{$store.getters.year+1}}</h2>
		</div>

		<div class="mask">
			<div>
				<h2 alt="previous">{{$store.getters.year}}</h2>
				<h1 alt="navigation">HALL OF FAME</h1>
				<h2 alt="next">{{$store.getters.year+1}}</h2>
			</div>
		</div>

	</header>
</template>
<script>
export default {
	name: 'Header',
	props : [  ], //NO LONGER PASSING IN TIMELINE OBJECT... USE STORE
	data() {
		return {

		}
	},
	mounted: function() {

		this.$root.$on("YEAR_READY", (year)=>{
            // in case we want to use this instead of passing timeline in props
        });

		/*
		FLIP: NOT NEEDED ANYMORE AS THE NAV IS OVER THE HEADER
        this.$root.$on("TOGGLE_NAV", ()=>{
        	this.flip = !this.flip;
        });
        */

    },
	methods: {

		onClick(evt){ // NAVIGATE TO YEAR AND/OR CLOSE NAV
			const alt = evt.target.getAttribute('alt');
			if( alt == 'navigation' ) this.$root.$emit('TOGGLE_NAV');
			if( alt == 'previous' ) this.navigateYear(false);
			if( alt == 'next' ) this.navigateYear(true);
		},

		navigateYear( forwards ){
			let year = this.$store.getters.year;
			year = ( forwards ) ? year+1 : year-1;
			if( year > 2019 | year < 2005 ) return;
			this.$store.commit('changeYear', year ); // TRIGGER STORE CHANGE NOW!!!
			// this.$root.$emit("SWITCH_YEAR", year);
		},

	}
}
</script>
