<template>
	<div class="awards-container"
		v-on:click.prevent="onClick"
		v-on:mousedown="onDown"
		v-touch:swipe="onSwipe">

		<div class="wrap">
			
			<ul>
				<li v-for="trophy in trophies">
					<img class="trophy" :src="'timeline/imgs/'+trophy.filename" :alt="trophy.alt">
					<div class="bottom">
						<div class="copy">
							<h3>{{trophy.type}}</h3>
							<p>{{trophy.name}}</p>
						</div>
					</div>
				</li>
			</ul>

		</div>

		<span class="arrow" alt="previous"></span>
		<span class="arrow" alt="next"></span>

	</div>
</template>
<script>
	export default {
		name: 'Awards',
		props : {
		    trophies : {
		        type: Array,
		        default: () => [
			        {
			        	'filename':'placeholder-award.png',
			        	'alt':'placeholder-award',
			        	'type': 'Winner',
			        	'name': 'what ever'
			        },
			        {
			        	'filename':'placeholder-award.png',
			        	'alt':'placeholder-award',
			        	'type': 'Nomination',
			        	'name': 'what ever 2'
			        },
		        ]
		    },
		},
		data() {
			return {
				el : {
					list : null,
					items : null,
					arrows : null,
				},
				drag: {
					downX: null,
					upX: null,
				},
				allowScroll: false,
				scroll : 0,
				current : 0,
				busy: 0,
			}
		},
		mounted: function() {
			
			// cache els
	  		this.el.list = this.$el.getElementsByTagName("ul")[0];
			this.el.items = this.$el.getElementsByTagName("li");
			this.el.arrows = this.$el.getElementsByClassName("arrow");

			this.initAwards();

			window.addEventListener('resize', this.onResize);
			if(this.allowScroll)this.$el.addEventListener("wheel", this.onScroll, true);

	    },

	    methods : {

	    	initAwards(evt) {
			

				// resize based on number of photos
				setTimeout(() => {

					// resize based on number of photos
					const tw = (this.el.items.length * 100) + '%';
					const w = (100 / this.el.items.length ) + '%';
					this.el.list.style.width  = tw;
					for (let i = 0; i < this.el.items.length; ++i) {
						const item = this.el.items[i];
						item.style.width = w;
					}

					if( this.el.items.length > 1 ) this.el.arrows[1].style.opacity = 1;

				}, 1000);
			},

			onResize(evt) {
				this.initAwards();
			},

	    	onClick(evt){
				if( evt.target.getAttribute('alt') == 'previous') this.navigateAward(false);
				if( evt.target.getAttribute('alt') == 'next') this.navigateAward(true);
			},

			onSwipe(direction){
				if( direction == "bottom" || direction == "top" ) return;
				const forwards = ( direction == 'left' ) ? true : false;
				this.navigateAward( forwards );
			},

			onDown(evt){
				if( evt.target.getAttribute('class').includes('trophy') ){
					this.drag.downX = evt.clientX
					document.onmousemove = this.elementDrag
					document.onmouseup = this.stopElementDrag
				}
			},

			elementDrag(evt){
				evt.preventDefault()
				this.drag.upX = evt.clientX;
			},

			stopElementDrag(){
				if( this.drag.upX ){
					const forwards = ( this.drag.upX < this.drag.downX ) ? true : false;
					this.navigateAward( forwards );
				}
				document.onmouseup = null
	      		document.onmousemove = null
			},

			onScroll(evt){

				evt.preventDefault(); // DON'T SCROLL THE TIMELINE

			    const delta = parseInt(evt.deltaY);

				const forwards = ( ( this.scroll - delta ) >= this.scroll ) ? true : false

			    //console.log( this.scroll, delta, forwards );

			    this.scroll -= delta;

			    this.navigateAward( forwards );

			},

			navigateAward( forwards ){
				if( this.busy ) return;
				let id = this.current;
				id = ( forwards ) ? id+1 : id-1;
				if( id < 0 ) id = 0;
				if( id > this.el.items.length-1 ) id = this.el.items.length-1;
				this.updateList(id);
			},

			updateList( id ){ // update the list based on id

				if( this.current == id ) return; // no change!!
				this.busy = true;
				
				this.el.arrows[0].style.opacity = ( id > 0 ) ? 1 : 0;
				this.el.arrows[1].style.opacity = ( id < this.el.items.length-1 ) ? 1 : 0;

				const size = this.el.items[0].clientWidth;



				this.el.list.style.transform = "translateX(-" + (size * id ) + "px)";

				this.current = id;

				setTimeout(() => {
					this.busy = false;
				}, 150);
			}

	    }
	    
	}
</script>
