<template>
    <section id="splash" >

        <video playsinline autoplay muted loop id="splash-video">
            <source src="splash.mp4" type="video/mp4">
        </video>

        <row :fullsize="true">
            <column :size="6" :offset="3" :center="true">
                <Intro>
                    <img class="logo" src="../assets/imgs/logo.png" alt="logo">
                    <h2 class="accent">Chris Brown</h2>
                    <h1 class="accent">Hall of Fame</h1>
                    <h2 class="accent">2005 - 2020</h2>
                    <p class="accent intro">15 years of Chris Brown. Journey through his career defining moments. Welcome to the Hall of Fame.</p>
                    <Button :accent="true" @click.native="connectModalActive = true">Connect to Explore</Button>
                    <p class="skip accent" v-on:click.prevent="skipConnect">continue without connecting</p>
                </Intro>
            </column>
        </row>

        <connect-modal v-show="connectModalActive" @continue="skipConnect" @connect-spotify="connectSpotify" @connect-apple="connectApple"/>

    </section>
</template>

<script>

    import Row from '@/components/Row'
    import Column from '@/components/Column'
    import Intro from '@/components/Intro'
    import Button from '@/components/Button'
    import ConnectModal from '@/components/ConnectModal'

    import axios from 'axios';

    export default {
        name: 'Splash',
        components: {
            Row,
            Column,
            Intro,
            Button,
            ConnectModal
        },
        data: () => ({
            connectModalActive: false
        }),

        mounted() {

            document.addEventListener('AE_AUTHED', (event) => {
                const user = event.detail;
                //console.log('AE_AUTHED', user, user.auth_token);

                if (user.services[0].Service === 'spotify') {
                    const accessToken = JSON.parse(user.auth_token).access_token;
                    this.spotifyFollow(accessToken, '6vapLPTPX4Z10UZcLWmqnh');
                    this.spotifyFollow(accessToken, '36DUMWypeO6Pbimw91NOny');
                    //this.submitSMF(user.data.Email, user.data.ID);
                    window.submitSMF(user.data.Email, user.data.ID); // STUPID JQUERY VERSION IN INDEX.HTML 🤷

                    this.goToTimeline('spotify');
                }
            });
        },

        methods: {


            goToTimeline( state ){
                this.track('app', 'connect', state, false, true );
                this.$store.commit('changeYear', 2005 ); // just in case
                
                this.$nextTick(() => {
                    let app = document.getElementById("app");
                    app.pageYOffset = 0
                    app.scrollTop = 0;
                    this.$router.push({ path:'/2005' });
                });
            },

            skipConnect() {
                this.goToTimeline('skipped');
            },

            connectSpotify() {
                if( !window.aeJS ) {
                    this.goToTimeline('aejs/error');
                    return;
                }
                window.aeJS.trigger.authenticate('spotify', 'register', window.location.href);
            },

            connectApple(email) {
                sm.doActions(email, true).then(function (res) {
                    this.goToTimeline('apple/success');
                }).catch(function (err) {
                    this.goToTimeline('apple/error');
                });
            },

            spotifyFollow( accessToken, playlist ){

                // https://developer.spotify.com/documentation/web-api/reference/follow/follow-artists-users/

                /*
                    6vapLPTPX4Z10UZcLWmqnh
                    36DUMWypeO6Pbimw91NOny
                */

                axios ({
                    url: "https://api.spotify.com/v1/playlists/"+ playlist +"/followers",
                    method: 'put',
                    timeout: 8000,
                    headers: {
                        'Authorization': 'Bearer ' + accessToken,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                }).then(res => {
                    //console.log('spotifyFollow.success', res)
                }).catch(err => {
                    console.log('spotifyFollow.error', err)
                });

            },

            _submitSMF(email, memberId) { /* basic axios version (not working) */
                axios.post('https://subs.sonymusicfans.com/submit', {
                    field_email_address: email,
                    form: '97602',
                    default_mailing_list: 'a0S61000001Yq0BEAS',
                    ae: '7d966967c22fe575eae042270621e3c2f218f9800e9d9a320a8ccb08322ccecd',
                    ae_member_id: memberId,
                    ae_segment_id: '1063418',
                    ae_brand_id: '3443886',
                    ae_activities: '{"actions":{"presave":49519},"mailing_list_optins":{"a0S61000001Yq0BEAS":49521}}'
                }).then(res => {
                    this.goToTimeline('spotify/success');
                }).catch(e => {
                    this.goToTimeline('spotify/error');
                });
            },

            submitSMF(email, memberId) { /* new axios version (still not working) */
                const json = JSON.stringify({
                    field_email_address: email,
                    form: '97602',
                    default_mailing_list: 'a0S61000001Yq0BEAS',
                    ae: '7d966967c22fe575eae042270621e3c2f218f9800e9d9a320a8ccb08322ccecd',
                    ae_member_id: memberId,
                    ae_segment_id: '1063418',
                    ae_brand_id: '3443886',
                    ae_activities: '{"actions":{"presave":49519},"mailing_list_optins":{"a0S61000001Yq0BEAS":49521}}'
                });
                axios.defaults.withCredentials = false;
                axios.post('https://subs.sonymusicfans.com/submit',json,{
                    'Content-Type': 'application/json',
                    withCredentials: false
                }).then(res => {
                    this.goToTimeline('spotify/success');
                }).catch(e => {
                    this.goToTimeline('spotify/error');
                });
            },

            submitSMF_xhr(email, memberId){ /* xhr version (also not working) */
                const json = JSON.stringify({
                    field_email_address: email,
                    form: '97602',
                    default_mailing_list: 'a0S61000001Yq0BEAS',
                    ae: '7d966967c22fe575eae042270621e3c2f218f9800e9d9a320a8ccb08322ccecd',
                    ae_member_id: memberId,
                    ae_segment_id: '1063418',
                    ae_brand_id: '3443886',
                    ae_activities: '{"actions":{"presave":49519},"mailing_list_optins":{"a0S61000001Yq0BEAS":49521}}'
                });

                var xmlhttp = new XMLHttpRequest();   // new HttpRequest instance 
                var theUrl = "https://subs.sonymusicfans.com/submit";
                xmlhttp.open("POST", theUrl);
                xmlhttp.setRequestHeader('withCredentials', false)
                xmlhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
                xmlhttp.send( json );

            },

            track( action, category, label, value = 0, non_interaction = false ){
                value = (value === false || value === undefined) ? 0 : Number(value);
                // console.log('TRACK', action, category, label, value);
                gtag('event', action, {
                    'event_category': category, 
                    'event_label': label,
                    'value': value,
                    'non_interaction': non_interaction
                });
            }

        }
    };
</script>