<template>
	<div class="song-container" :data-song="alt">
		<div class="wrap">
			<img :src="'timeline/imgs/'+filename" :alt="alt">
		</div>
	</div>
</template>

<script>
import inViewport from 'vue-in-viewport-mixin'

export default {
	mixins: [inViewport],
	name: 'Song',
	data() {
		return {
			inViewport: {
				active: false
			}
		}
	},
	props : {
		filename : {
	        type: String,
	        default: 'song.png'
	    },
	    alt : {
	        type: String,
	        default: 'song title'
			},
			audio: {
				type: String
			}
		},
		mounted: function() {
			setTimeout(() => {
				let container = this.$el;
				let column = container.parentNode;
				let row = column.parentNode;
				let rowNext = row.nextSibling;

				//row.style.marginTop="0";
				rowNext.style.marginTop="0";
			}, 1000);
			setTimeout(() => {
				this.$watch('inViewport.fully', () => {
					this.$root.$emit('AUDIO_PLAY', {
						name: this.alt,
						file: this.audio
					})
				});
			}, 300);
		},
		methods: { 
			playSong() {
				this.$root.$emit('AUDIO_PLAY', {
					name: this.alt,
					file: this.audio
				})
			}
		}
}
</script>
