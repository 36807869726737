<template>
	<div class="gallery-container"
	v-on:click.prevent="onClick"
	v-on:mousedown="onDown"
	v-touch:swipe="onSwipe">

		<div class="frame"></div>

		<div class="wrap">
			<ul>
				<li v-for="photo in photos">
					<img :src="'timeline/imgs/'+photo.filename" :alt="photo.alt">
				</li>
			</ul>
		</div>
		
		<span class="arrow icon-arrow-side" alt="previous"></span>
		<span class="arrow icon-arrow-side" alt="next"></span>

	</div>
</template>
<script>
export default {
	name: 'Gallery',
	props : {
		photos : {
	        type: Array,
	        default: () => [
		        {
		        	'filename':'placeholder-portrait.jpg',
		        	'alt':'placeholder-landscape',
		        },
		        {
		        	'filename':'placeholder-portrait.jpg',
		        	'alt':'placeholder-landscape',
		        }
	        ]
	    },
    },

	data() {
		return {
			el : {
				wrap : null,
				list : null,
				items : null,
				arrows : null
			},
			drag: {
				downX: null,
				upX: null,
			},
			allowScroll: false,
			scroll : 0,
			current : 0,
			busy: 0,
			widths: [],
		}
	},

	mounted: function() {

		// cache els
		this.el.wrap = this.$el.getElementsByClassName("wrap")[0];
		this.el.frame = this.$el.getElementsByClassName("frame")[0];
		this.el.list = this.$el.getElementsByTagName("ul")[0];
		this.el.items = this.$el.getElementsByTagName("li");
		this.el.arrows = this.$el.getElementsByClassName("arrow"); 

		this.initGallery();

		window.addEventListener('resize', this.onResize);

		if(this.allowScroll)this.$el.addEventListener("wheel", this.onScroll, true);
  },

	methods : {

		initGallery(evt) {
			

			// resize based on number of photos
			setTimeout(() => {

				let widths = [];
				let tw = 0;

				this.el.items.forEach(function(item) {
					widths.push(item.clientWidth);
					tw = tw + item.clientWidth;
				});

				this.widths = widths;
				this.el.wrap.style.marginLeft = ( (0.5 * window.innerWidth) - (0.5 * this.widths[0]) ) + 'px';
				this.el.list.style.width  = (tw + 20) + 'px';

			}, 1000);
		},

		onResize(evt) {
			this.initGallery();
		},

		onClick(evt){
			if( evt.target.getAttribute('alt') == 'previous') this.navigatePhoto(false);
			if( evt.target.getAttribute('alt') == 'next') this.navigatePhoto(true);
		},

		onDown(evt){
			if( evt.target.getAttribute('class').includes('frame') ){
				this.drag.downX = evt.clientX
				document.onmousemove = this.elementDrag
				document.onmouseup = this.stopElementDrag
			}
		},

		elementDrag(evt){
			evt.preventDefault()
			this.drag.upX = evt.clientX;
		},

		stopElementDrag(){
			if( this.drag.upX ){
				const forwards = ( this.drag.upX < this.drag.downX ) ? true : false;
				this.navigatePhoto( forwards );
			}
			document.onmouseup = null
      		document.onmousemove = null
		},

		onSwipe(direction){
			if( direction == "bottom" || direction == "top" ) return;
			const forwards = ( direction == 'left' ) ? true : false;
			this.navigatePhoto( forwards );
		},
	
		onScroll(evt){

			// if( evt.target != this.el.wrap ) return; // TRICK IF YOU DON"T WANT TO SCROLL ON ENTIRE EVT CURRENT TARGET AND ONLY SPECFIC TARGET (USE POINTER EVENT NONE TOO). ONLY SCROLL IF ON CENTER PHOTO

			evt.preventDefault(); // DON'T SCROLL THE TIMELINE

				const delta = parseInt(evt.deltaY);

				const forwards = ( ( this.scroll - delta ) >= this.scroll ) ? true : false

				// console.log( this.scroll, delta, forwards );

				this.scroll -= delta;

				this.navigatePhoto( forwards );

		},

		navigatePhoto( forwards ){
			if( this.busy ) return;
			let id = this.current;
			id = ( forwards ) ? id+1 : id-1;
			if( id < 0 ) id = 0;
			if( id > this.el.items.length-1 ) id = this.el.items.length-1;
			this.updateList(id);
		},

		updateList( id ){ // update the list based on id

			if( this.current == id ) return; // no change!!

			this.busy = true;
			
			this.el.arrows[0].style.opacity = ( id > 0 ) ? 1 : 0;
			this.el.arrows[1].style.opacity = ( id < this.el.items.length-1 ) ? 1 : 0;

			let size = 0;
			let i;

				for (i = 0; i < id; i++) {
					let currentHalf = this.widths[i]/2;
					let nextHalf = this.widths[i+1]/2;
					size = size + (currentHalf + nextHalf);
				}
				this.el.list.style.transform = "translateX(-" + (size) + "px)";

			this.current = id;

			setTimeout(() => {
				this.busy = false;
			}, 150);
		}

	}
}
</script>
