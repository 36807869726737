<template>

	<Year :year="2016">

		<row :fullsize="true">
			<column :size="6" :center="true">
				<Title :hero="true" :albums="2" :singles="1" :nominations="3">2020</Title>
			</column>
			<column :size="5" :center="true">
				<Photo  :inside="true" :portrait="true"></Photo>
			</column>
		</row>

		<row :spaced="true">
			<column :size="12">
				<Photo>What if we have an<br>image across the page</Photo>
			</column>
		</row>

		<row :spaced="true">
			<column :size="6">
				<Photo>and what if that<br>image is portrait</Photo>
			</column>
		</row>

		<row :spaced="true">
			<column :size="4" :offset="1">
				<Subtitle :date="'04'">JAN</Subtitle>
			</column>
		</row>

		<row>
			<column :size="5" :offset="2">
				<Song></Song>
			</column>
			<column :size="3" :offset="1">
				<Badge :rank="1" :wild="true">Badge with two lines</Badge>
			</column>
		</row>

		<row>
			<column :size="12" :framed="true">
				<Video :youtube="'g4P0HN5MPk4'">video on the right</Video>
				<Paragraph>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</Paragraph>
            	<Button :href="'http://www.google.com'">CTA</Button>
			</column>
		</row>

		<row :spaced="true" :fullsize="true" :breakout="true">
			<column :size="12" :center="true">
				<Gallery>gallery</Gallery>
			</column>
		</row>

		<row :centered="true" :spaced="true">
			<column :size="6">
				<Video>video next to copy</Video>
			</column>
			<column :size="6" :center="true">
				<Paragraph>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
				</Paragraph>
			</column>
		</row>

		<row>
			<column :size="6" :offset="6">
				<Photo>Image on the right</Photo>
			</column>
		</row>

		<row>
			<column :size="6">
				<Badge :rank="1">Badge with two lines</Badge>
				<Paragraph>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
				</Paragraph>
				<Button :href="'http://www.google.com'">CTA</Button>
			</column>
		</row>

		<row>
			<column :size="4" :offset="8">
				<Title>Title</Title>
			</column>
		</row>

		<row>
			<column :size="4" :offset="4">
				<Subtitle>Sub Title</Subtitle>
			</column>
		</row>

		<row>
			<column :size="12">
				<Lyrics>It's alright, I'm not dangerous<br>When you're mine, I'll be generous<br>You're irreplaceable, a collectible<br>Just like fine China</Lyrics>
			</column>
		</row>

		<row :fullsize="true" :background="true">
			<column :size="6" :center="true">
				<Title :nominations="2" :wins="2">2018<br>Bet<br>Awards</Title>
			</column>
			<column :size="6" :center="true">
				<Awards>Best New Artist</Awards>
			</column>
		</row>

		<row>
			<column :size="4" :offset="4">
				<End>2006</End>
			</column>
		</row>


		<row :fullsize="true">
			<column :size="7" :center="true">
				<Title :finished="true" :hero="true" :custom="'Share the hall of fame experience'">#Team Breezy</Title>
			</column>
			<column :size="5" :center="true">
				<Social></Social>
			</column>
		</row>

	</Year>

</template>

<script>

	/*
		Bootstrap Grid: https://bootstrap-vue.org/docs/components/layout#layout-and-grid-system
	*/

	import Year from '@/components/Year'
	import Row from '@/components/Row'
	import Column from '@/components/Column'

	import Video from '@/components/Video'
	import Gallery from '@/components/Gallery'
	import Photo from '@/components/Photo'
	import Social from '@/components/Social'
	import Song from '@/components/Song'
	import Title from '@/components/Title'
	import Subtitle from '@/components/Subtitle'
	import Badge from '@/components/Badge'
	import Paragraph from '@/components/Paragraph'
	import Lyrics from '@/components/Lyrics'
	import Button from '@/components/Button'
	import Awards from '@/components/Awards'
	import End from '@/components/End'

    export default {
        name: 'StyleKit',
        components: {
        	Year,
        	Row,
        	Column,
        	Title,
        	Subtitle,
        	Badge,
        	Paragraph,
        	Lyrics,
        	Button,
        	Video, 
        	Gallery,
        	Photo,
        	Social,
        	Song,
        	Awards,
        	End
        },
        data: () => ({
        //
        }),
    };
</script>