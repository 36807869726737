
import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import store from './store'
import Clipboard from 'v-clipboard'
import Vue2TouchEvents from 'vue2-touch-events'
import VueAnime from 'vue-animejs';

Vue.config.productionTip = false
Vue.use(Vuex)
Vue.use(Clipboard)
Vue.use(Vue2TouchEvents, {
    disableClick: true,
    touchClass: 'touch',
    tapTolerance: 10,
    touchHoldTolerance: 400,
    swipeTolerance: 30,
    longTapTimeInterval: 400
});

Vue.use(VueAnime)

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
