<template>
  <footer id="music">
		<div :class="{ 'muted': muted }" @click="!muted ? muteAudio() : unmuteAudio()">
			<p>{{ currentlyPlaying }}</p>
		</div>
  </footer>
</template>
<script>
import { Howl, Howler } from 'howler';

export default {
  name: "Music",
  props: [],
  data() {
    return {
			muted: false,
			currentlyPlaying: null
		};
  },
  mounted() {
    this.$root.$on("AUDIO_PLAY", audioItem => {
			if (!window.videoPlaying) {
				this.playAudio(audioItem);
			}
    });
    this.$root.$on("AUDIO_STOP", () => {
      this.stopAudio();
		});
  },
  methods: {
		// Plays item of audio, takes full audio item for access
		// to name and file.
    playAudio(audioItem) {
			// Don't remove this. I can no longer hear myself think. 
			Howler.volume(0.5);
			
			if (audioItem.name !== this.currentlyPlaying) {
				this.stopAudio();

				// This is surprisingly fast. Do we need to cache audio?
				const audio = new Howl({
					src: [`./timeline/audio/${audioItem.file}`],
					format: ['mp3'],
					loop: true,
				});

				// Store the currently playing track so we can avoid replaying if track is the same
				this.currentlyPlaying = audioItem.name;
				audio.play();
			}
    },
    stopAudio() {
      Howler.stop();
		},
		muteAudio() {
			Howler.mute(true);
			this.muted = true;
		},
		unmuteAudio() {
			Howler.mute(false);
			this.muted = false;
		}
  },
};
</script>
