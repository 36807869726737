<template>
	<nav id="nav"
		:class="{ show : open }"
		v-touch:swipe="onSwipe"
		v-on:mouseover="onOver"
		v-on:mouseout="onOut"
		v-on:hoover.prevent="onHover"
		v-on:click.prevent="onClick">

		<div class="wrap">
			<h2 class="black">20</h2>
			<ul>
				<li :class="year.toString()" v-for="year in 2019" v-if="year >= 2005" :value="year">
					<h2 class="white">{{year.toString().slice(-2)}}</h2>
				</li>
			</ul>
		</div>
		<div class="icon-arrow-side arrow"></div>
	</nav>
</template>
<script>
	export default {

		name: 'Navigation',
		props : [ ], //NO LONGER PASSING IN TIMELINE OBJECT... USE STORE
		data() {
			return {
				open : false,
				busy : false,
				scroll : 0,
				current : { // local only
					id : null,
					year : null,
				},
				el : {
					root : null,
					list : null,
					items : null,
					arrow : null
				}
			}
		},

		mounted: function() {
			
			this.el.root = document.documentElement;

			this.el.list = this.$el.getElementsByTagName("ul")[0];
			this.el.items = this.el.list.getElementsByTagName("li");
			this.el.arrow = this.$el.getElementsByClassName("arrow");


	        let suscribe = this.$store.subscribe((mutation, state) => {
                switch(mutation.type){
                    case 'changeYear':
                        this.updateList( this.yearToId( mutation.payload ) );
                        break;
                }
            });

			this.$root.$on("TOGGLE_NAV", ()=>{
				if(!this.open) this.reset();
            	this.open = !this.open;
            });

            this.$el.addEventListener("wheel", this.onScroll, true);

            this.reset();

	    },

		methods: {


			reset(){
				this.current.year = this.$store.getters.year;
				this.updateList( this.yearToId(this.current.year) ); // jump to a specific year 
			},

			onSwipe(direction){
				if( direction == "left" || direction == "right" ) return;
				const forwards = ( direction == 'top' ) ? true : false;
				this.changeYearId( forwards );
			},

			onScroll(evt){
				
				//https://stackoverflow.com/questions/30892830/detect-scrolling-on-pages-without-a-scroll-bar

			    const delta = parseInt(evt.deltaY);
			    
			   // console.log( evt.currentTarget, evt.target, evt.deltaY, delta );

				const forwards = ( ( this.scroll - delta ) >= this.scroll ) ? false : true;

			    //console.log( this.scroll, delta, forwards );

			    this.scroll -= delta;

			    this.changeYearId( forwards );

			},

			onClick(evt){ // NAVIGATE TO YEAR AND/OR CLOSE NAV

				const name = evt.target.getAttribute('class');

				if( name == 'wrap' || name == 'show') { // background or nav or close
					this.$root.$emit("TOGGLE_NAV")
				}else if( name.includes('arrow') || name == this.current.year ) { // can click on arrow or the year
					this.$root.$emit("TOGGLE_NAV")
					this.$store.commit('changeYear', this.current.year );
				}else if( Number(name) ){
					this.updateList( this.yearToId( Number(name) ) );
				}

			},

			onOver(evt){
				const name = evt.target.getAttribute('class');
				if( name == this.current.year ) this.el.arrow[0].classList.add("white");
			},
			onOut(evt){
				const name = evt.target.getAttribute('class');
				if( name == this.current.year ) this.el.arrow[0].classList.remove("white");
			},

			yearToId( year ){
				let id;
				for (let i = 0; i < this.el.items.length; ++i) {
					const innerText = this.el.items[i].textContent.replace(/[\n\r]+|[\s]{2,}/g, ' ').trim()
					if ( innerText == year.toString().slice(-2) ) {
						id = i;
						break;
					}
				}
				//console.log( id, year );
				return id;
			},

			changeYearId( forwards ){ // change id when user scrolls the list
				if( this.busy ) return;
				let id = this.current.id;
				id = ( forwards ) ? id+1 : id-1;
				if( id < 0 ) id = 0;
				if( id > this.el.items.length-1 ) id = this.el.items.length-1;
				//console.log( id, 0, this.el.items.length );
				this.updateList(id);
			},

			updateList( id ){ // update the list based on

				if( this.current.id == id ) return; // no change!!

				this.busy = true;

				for (let i = 0; i < this.el.items.length; ++i) {
					const item = this.el.items[i];
					const o = 1 / ( Math.abs(id - i) + 1 );
					item.style.opacity = o;
				}

				const year = '20' + this.el.items[id].innerText;
				const size = this.el.list.clientHeight; // 168px
				let values = getComputedStyle( this.el.root ).getPropertyValue("--color-" + year ).replace(/[()]/g,'').split(',');

				this.$el.style.background =  values[0];
				this.el.list.style.transform = "translateY(-" + (size * id ) + "px)";

				this.current.id = id;
				this.current.year = year;

				setTimeout(() => {
					this.busy = false;
				}, 150);
			}


		}
	}
</script>
