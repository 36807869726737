<template>
	<div class="end" v-on:click.prevent="onClick">
		<h2><slot></slot></h2>
		<span class="arrow icon-arrow-down"></span>
	</div>
</template>
<script>
export default {
	name: 'End',
	props : [],
	data() {
		return {

		}
	},
	methods: {
		onClick(){
			console.log('CLICK END', this.next );
			const next = this.$store.getters.year +1;
			this.$store.commit('changeYear', next); // TRIGGER IN STORE NOW!!!
			// this.$root.$emit("SWITCH_YEAR", this.next); 
		}
	}
}
</script>
