<template>
	<div class="social-container">
		<div class="wrap frame" :class="{invert:invert}">
			<a 
				:href="'https://www.facebook.com/sharer/sharer.php?u=' + url"
				target="_blank"
			>
				<span class="icon-facebook"></span>
			</a>
			<a 
				:href="'https://twitter.com/share?url=' + url"
				target="_blank"
			>
				<span class="icon-twitter"></span>
			</a>
			<copy :url="url"></copy>
		</div>
	</div>
</template>
<script>
import Copy from '@/components/Copy'

export default {
	name: 'Social',
	components: {
		Copy
	},
	data() {
		return {
			url: 'http://halloffame.chrisbrownworld.com'
		}
	},
	props : {
		invert : { // frame can be switched from tall to wide fit
			type : Boolean,
			default : false
		},
		inside : { // frame can be inside the image
			type : Boolean,
			inside : false
		},
		filename : {
	        type: String,
	        default: 'placeholder-landscape.jpg'
	    },
	    alt : {
	        type: String,
	        default: 'landscape'
	    },
    }
}
</script>
