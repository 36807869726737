import Vue from 'vue'
import Router from 'vue-router'
import Timeline from '../views/Timeline.vue'
import Stylekit from '../views/Stylekit.vue'
import Splash from '../views/Splash.vue'

Vue.use(Router);

export default new Router({
	// mode: 'history',
	mode: 'hash',
	routes: [
		{ path: '/', redirect: '/splash' },
		{
			path: '/splash',
			alias: '/2020',
			name: 'splash',
			component: Splash
		},
		{
			path: '/stylekit',
			name: 'stylekit',
			component: Stylekit
		},
		{
			path: '/test',
			name: 'test',
			component: Timeline
		},
		{
			path: '/:year',
			alias: '/timeline',
			name: 'timeline',
			component: Timeline
		},
		{
		    path :'*',
		    redirect: '/splash' 
		}
	]
})
