<template>
	<button v-on:click.prevent="onClick" class="btn" :class="{ accent : accent}"><p :class="{ accent : accent}"><slot></slot></p></button>
</template>
<script>
export default {
	name: 'Button',
	props : ['accent', 'href'],
	data() {
		return {

		}
	},
	mounted: function() {
		// console.log('MONUTED', this.href);
    },
	methods:{
		onClick(){// for tracking links!!
			// console.log('CLICK', this.href);
			if( !this.href ) return;
			if( !/^(http:|https:)/i.test( this.href ) ) return;	// local link???
			window.open( this.href, '_blank');
			
		},
	}
}
</script>
