<template>
	<div :class="[
			'row',
			fullsize ? 'fullsize' : '',
			spaced ? 'spaced' : '',
			breakout ? 'breakout' : '',
			background ? 'background' : '',
			centered ? 'centered' : '',
	    ]"><slot></slot>
	</div>
</template>
<script>
	/*
		TO DO:
		* allow different backgrounds
	*/
	export default {
		name: 'Row',
		props : ['breakout', 'spaced', 'fullsize', 'background', 'centered'],
		data() {
			return {

			}
		},
	}
</script>
