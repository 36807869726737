<template>
	<div class="photo-container">
		<div class="wrap frame" :class="{invert:invert, inside:inside}">
			<img :src="'timeline/imgs/'+filename" :alt="alt">
		</div>
		<h3><slot></slot></h3>
	</div>
</template>
<script>
export default {
	name: 'Photo',
	props : {
		invert : { // frame can be switched from tall to wide fit
			type : Boolean,
			default : false
		},
		inside : { // frame can be inside the image
			type : Boolean,
			inside : false
		},
		filename : {
	        type: String,
	        default: 'placeholder-landscape.jpg'
	    },
	    alt : {
	        type: String,
	        default: 'landscape'
	    },
    }
}
</script>
