<template>
	<div class="lyrics-container">
		<p><slot></slot></p>
	</div>
</template>
<script>
export default {
	name: 'Paragraph',
	props : [],
	data() {
		return {

		}
	},
}
</script>
