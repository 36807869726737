<template>
	<footer id="footer">

		<p>
			2020 © Sony Music Entertainment
			&nbsp;&nbsp;|&nbsp;&nbsp;
			<a 
				v-on:click.prevent="onClick('https://www.sonymusic.com/privacy-policy/')" 
				href="https://www.sonymusic.com/privacy-policy/" 
				target="_blank"
			>Privacy Policy</a>
			&nbsp;&nbsp;|&nbsp;&nbsp;
			<a 
				v-on:click.prevent="onClick('https://www.sonymusic.com/terms-and-conditions/')" 
				href="https://www.sonymusic.com/terms-and-conditions/" 
				target="_blank"
			>Terms & Conditions</a>
			&nbsp;&nbsp;|&nbsp;&nbsp;
			<a 
				v-on:click.prevent="onClick('https://www.sonymusic.com/feedback/')" 
				href="https://www.sonymusic.com/feedback/" 
				target="_blank"
			>Send Us Feedback</a>
			&nbsp;&nbsp;|&nbsp;&nbsp;
			<a 
				v-on:click.prevent="onClick('https://www.sonymusic.com/ccpa-contact-form/')" 
				href="https://www.sonymusic.com/ccpa-contact-form/" 
				target="_blank"
			>Do Not Sell My Personal Data</a>
			&nbsp;&nbsp;|&nbsp;&nbsp;
			<a 
				v-on:click.prevent="onClick('https://www.sonymusic.com/privacy-policy/#your-california-privacy-rights')" 
				href="https://www.sonymusic.com/privacy-policy/#your-california-privacy-rights" 
				target="_blank"
			>Your California Privacy Rights</a>
			&nbsp;&nbsp;|&nbsp;&nbsp;
			<a 
				v-on:click.prevent="onClick('https://whymusicmatters.com/')" 
				href="https://whymusicmatters.com/" 
				target="_blank"
			>Why Music Matters</a>
		</p>


	</footer>
	
</template>
<script>
export default {
	name: 'Header',
	props : [ 'nav' ],
	data() {
		return {
			
		}
	},
	mounted: function() {

    },
	methods: {

		onClick( href ){ // for tracking links!!
			// console.log('CLICK', href);
			if( !href ) return;
			if( !/^(http:|https:)/i.test( href ) ) return;	// local link???
			window.open( href, '_blank');
			
		},
	}
}
</script>
