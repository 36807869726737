<template>

    <Year :year="$store.getters.year">

        <row v-for="row in rows"
            :fullsize="row.fullsize"
            :spaced="row.spaced"
            :breakout="row.breakout"
            :background="row.background"
        >

            <column v-for="column in row.columns"
                :size="column.size"
                :offset="column.offset"
                :center="column.center"
                :framed="column.framed"
            >
                <component v-for="component in column.components"
                    :is="component.type"
                    :hero="component.hero"
                    :albums="component.albums"
                    :singles="component.singles"
                    :nominations="component.nominations"
                    :awards="component.awards"
                    :wins="component.wins"
                    :inside="component.inside"
                    :filename="component.filename"
                    :trophies="component.trophies"
                    :photos="component.photos"
                    :date="component.date"
                    :rank="component.rank"
                    :wild="component.wild"
                    :youtube="component.youtube"
                    :href="component.href"
                    :custom="component.custom"
                    :audio="component.audio"
                    :alt="component.alt"
                    :ref="component.type === 'Song' ? component.alt : null"
                >{{component.slot}}</component>

            </column>

        </row>

    </Year>

</template>
<script>
    import Year from '@/components/Year'
    import Row from '@/components/Row'
    import Column from '@/components/Column'
    import Video from '@/components/Video'
    import Gallery from '@/components/Gallery'
    import Photo from '@/components/Photo'
    import Song from '@/components/Song'
    import Title from '@/components/Title'
    import Subtitle from '@/components/Subtitle'
    import Badge from '@/components/Badge'
    import Paragraph from '@/components/Paragraph'
    import Lyrics from '@/components/Lyrics'
    import Social from '@/components/Social'
    import Button from '@/components/Button'
    import Awards from '@/components/Awards'
    import End from '@/components/End'

    export default {
        name: 'Timeline',
        props: [],
        components: {
            Year,
            Row,
            Column,
            Title,
            Subtitle,
            Badge,
            Paragraph,
            Lyrics,
            Button,
            Video, 
            Gallery,
            Photo,
            Song,
            Social,
            Awards,
            End
        },
        created: function () {

            /* MIGHT BE NEEDED LATER... BUT THE CONTENT USES COMPUTED TO CHANGE
            let suscribe = this.$store.subscribe((mutation, state) => {
                switch(mutation.type){
                    case 'setData':
                        this.onTimelineDataSet();
                        break;
                }
            });
            */
            
        },
        computed:{
            rows: function () { // idea from https://stackoverflow.com/questions/48933606/v-for-and-v-if-not-working-together-in-vue-jså
                return this.$store.getters.data;
            },
        },

        methods: {

            onTimelineDataSet(){

                /*
                    Timeline data has been updated and computed function should already kick in! Later we might want to do tranistions in/out etc...
                */

                //console.log( 'TIMELINE DATA', this.$store.getters.data );


            }
        }
    };
</script>