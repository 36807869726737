<template>
	<section :id="'timeline-'+year"><slot></slot></section>
</template>
<script>
export default {
	name: 'Year',
	props : ['year'],
	data() {
		return {

		}
	},
}
</script>
