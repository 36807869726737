<template>
    <div class="video-container">

        <div class="wrap">

            <div class="wrap sixteen-nine">

                <button class="play-video" v-if="!showVideo" @click="playVideo">
                    <svg viewBox="0 0 29 50" xmlns="http://www.w3.org/2000/svg"><path d="M1.32 36.412l25-17-25-17z" stroke="#FFF" stroke-width="2" fill="none" fill-rule="evenodd"/></svg>
                    <slot></slot>
                </button>

                <img :src="'timeline/imgs/'+filename" :alt="alt" v-show="!showVideo"/>

                <iframe
                    v-show="showVideo"
                    ref="player"
                    :src="`https://www.youtube.com/embed/${youtube}?enablejsapi=1`"
                    frameborder="0"
                    allow="encrypted-media;"
                ></iframe>

            </div>
            
        </div>

        <!-- <h3><slot></slot></h3> -->

    </div>

</template>
<script>
    
    export default {
      name: "Video",
        props : {
            filename : {
                type: String,
                default: 'placeholder-landscape.jpg'
            },
            alt : {
                type: String,
                default: 'award'
            },
            youtube : {
                type: String,
                default: 'g4P0HN5MPk4'
            }
        },
        data() {
            return {
                showVideo: false,
                player: null
            };
        },
        methods: {
            playVideo() {
                const onReady = event => {
                    event.target.playVideo()
                }

                const onStateChange = event => {
                    if (event.data === -1 || event.data === 0 || event.data === 2) {
                        window.videoPlaying = false
                        this.$root.$emit('AUDIO_STOP')
                        return
                    }
                    window.videoPlaying = true;
                    console.log('playing', window.videoPlaying)
                }

                this.showVideo = true;

                this.player = new window.YT.Player(this.$refs.player, {
                    origin: window.location.origin,
                    events: {
                        onReady,
                        onStateChange
                    }
                });
            }
        }
    };
</script>
