<template>
	<div class="subtitle-container">
		<h1 class="accent"><slot></slot></h1>
		<h2 v-if="date">{{date}}</h2>
	</div>
</template>
<script>
	/*
		TO DO:
		* style it up
	*/
	export default {
		name: 'Subtitle',
		props : ['date'],
		data() {
			return {

			}
		},
	}
</script>
