import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// local storage idea https://medium.com/@yingshaoxo/how-to-use-store-in-vue-js-cce2a1329e33

export default new Vuex.Store({
	state: {
		timeline : {
			useCache: true,
			useStorage: true,
            year : null,
            data : null,
            percent : 0, //percent scrolled
        },
        cache:{

        }
	},
	mutations: {
		async changeYear(state, year){
			year = ( year && ( year >= 2005 && year <= 2019 ) ) ? year : 2005; // INCLUDES FALLBACK DEFAULT!
			if( state.timeline.year == year ) return; // DO NOTHING... NO CHANGE
			state.timeline.year = Number( year );
			this.commit('unsetData');
			await this.dispatch('getData', year);
		},
		updatePercent(state, percent){
			state.timeline.percent = percent;
		},
		unsetData(state){
			state.timeline.data = null;
		},
		setData(state, data){
			state.cache[ state.timeline.year ] = data;
			localStorage.setItem('store/cache', JSON.stringify(state.cache)); // ALSO CACHE TO LOCAL STORAGE
			state.timeline.data = data;
		},
		initialiseStore(state) { // CHECK LOCAL STORAGE ON APP START
			if ( state.timeline.useStorage && localStorage.getItem('store/cache') ) {
				const localCache = Object.assign(state.cache, JSON.parse(localStorage.getItem('store/cache')));
				// this.replaceState( Object.assign(state.cache, JSON.parse(localStorage.getItem('store/cache'))) );
				state.cache = localCache;
				//console.warn('Found localStorage', localCache  );
			}
		}
	},
	getters:{
		year: state => {
			return ( state.timeline.year ) ? state.timeline.year : null;
		},
		percent: state => {
			return ( state.timeline.percent ) ? state.timeline.percent : 0;
		},
		data: state => {
			return ( state.timeline.data ) ? state.timeline.data : null;
		}
	},
	actions: {
		async getData(){

			const year = this.state.timeline.year;
			const json = 'timeline/' + year + '.json';

			if( this.state.timeline.useCache && this.state.cache[ year ] ) {
				//console.warn('Loaded cached data instead of JSON', json );
				return this.commit('setData', this.state.cache[ year ]);
			}

			/* THIS DID NOT WORK FOR CHECKING IF THE FILE EXISTS !!
			try {
				file = require( '..' + path + file );
				console.log( file );
			} catch (e) {
				console.warn('Failed to get timeline data. Missing or broken JSON', path + file, '(using fallback instead)' );
			  	file = 'stylekit.json'
			}
			*/

			return fetch( json)
				.then( result => result.json())
				.then( data  => {
					this.commit('setData', data);
				}).catch(error => {
					console.warn('Failed to get timeline data. Missing or broken JSON', json, error );
				});
		}
	},
	modules: {

	}
})