<template>
    <div id="app">
        <router-view/>
        <Share/>
        <Music v-if="$route.name != 'splash'"/>
        <Header v-if="$route.name != 'splash'"/>
        <Navigation v-if="$route.name != 'splash'" />
        <Footer :class="{black : footer.show, show: $route.name == 'splash' | footer.show | this.$store.getters.percent > 99 }"/>
    </div>
</template>

<script>
    import Header from '@/components/Header'
    import Footer from '@/components/Footer'
    import Navigation from '@/components/Navigation'
    import Share from '@/components/Share'
    import Music from '@/components/Music'

    export default {
        name: 'App',
        components: {
            Header,
            Footer,
            Navigation,
            Share,
            Music,

        },
        data: () => ({
            metag : false,
            trackScroll: {
                start : true,
                half : true,
                end : true
            },
            footer : {
                show : false,
            },
            anime : {
                els: null,
                timeline: null
            }
        }),

        created: function () {

            // TRACK 
            this.track('app', 'created', this.$router.currentRoute.name, this.$route.params.year, true );

            // STORE STUFF
            this.$store.commit('initialiseStore');

            let suscribe = this.$store.subscribe((mutation, state) => {
                switch(mutation.type){
                    case 'changeYear':
                        this.onYearChanged( mutation.payload );
                        break;
                    case 'setData':

                        //console.log( 'setDta.test scroll', app.pageYOffset, app.scrollTop);

                        this.$nextTick(() => {
                            let app = document.getElementById("app");
                            //app.pageYOffset = 0
                            app.scrollTop = 0;
                        });

                        break;
                }
            });

            this.$store.commit('changeYear', this.$route.params.year ); // switchYear can handle undefined and invalid params

            // TO DO: re route user back to splash view if params is junk 
        },

        mounted: function() {

            let app = document.getElementById("app");
            app.addEventListener('scroll', this.onScroll);

            this.$root.$on("TOGGLE_NAV", ()=>{
                this.footer.show = !this.footer.show;
            });



            setTimeout(()=>{

                this.initAnimation();

            }, 500);

         

            

        },

        methods: {


            initAnimation(){

                const SELECTOR = '.row';
                const ANIMATE_CLASS_NAME = 'animate';

                const animate = element => (
                    element.classList.add(ANIMATE_CLASS_NAME)
                );

                const isAnimated = element => (
                    element.classList.contains(ANIMATE_CLASS_NAME)
                );

                const intersectionObserver = new IntersectionObserver((entries, observer) => {
                    entries.forEach((entry) => {

                        console.log( entry.target, entry.intersectionRatio );
                        // when element's is in viewport,
                        // animate it!
                        if (entry.intersectionRatio > 0.0003) {
                            animate(entry.target);
                            
                        }

                        // remove observer after animation
                        // observer.unobserve(entry.target);
                    });
                });

                // get only these elements,
                // which are not animated yet
                const elements = [].filter.call(
                    document.querySelectorAll(SELECTOR),
                    element => !isAnimated(element, ANIMATE_CLASS_NAME),
                );

                // start observing your elements
                elements.forEach((element) => intersectionObserver.observe(element));

            },

            onYearChanged( year ){

                if( !year ) return true;

                // PROBABLY SHOULD DO THIS BEFORE STORE... BUT FOR NOW THIS HAPPENS AFTER WE UPDATE STORE
                if( this.$route.name == 'timeline' && this.$route.params.year != year ) this.$router.push({ path: `/${year}` });

                // META (NOT TOTALLY GOING TO SAVE US... BUT IT'S SOMETHING)
                const title = "The Hall of Fame | " + year;
                document.querySelector('head meta[name="title"]').setAttribute('content', title );
                document.querySelector('head title').textContent = title;

                // AND NEED TO UPDATE THE CSS VARS AFTER YEAR HAS CHANGED
                const root = document.documentElement;
                let values = getComputedStyle(root).getPropertyValue("--color-" + year ).replace(/[()]/g,'').split(',');
                root.style.setProperty("--accent-color", values[0] );
                root.style.setProperty("--badge-sprite-position-x", values[1] );
                root.style.setProperty("--badge-sprite-position-y", values[2] );
                root.style.setProperty("--splash-sprite-position-x", values[3] );
                root.style.setProperty("--splash-sprite-position-y", values[4] );

                this.track('app', 'updated', this.$router.currentRoute.name, year, true );

                this.trackScroll.start = true;
                this.trackScroll.half = true;
                this.trackScroll.end = true;

                if( !this.metag){
                    this.metag = true;
                    console.log('%c Modern English 🎤', 'color: white; background: '+values[0]+'; padding: 2px 4px;');
                }

                setTimeout(()=>{

                    this.initAnimation();

                }, 500);
            },

            onScroll(evt){

                let el = evt.currentTarget;

                // console.log(
                //      el.scrollTop,
                //      el.clientHeight,
                //      el.offsetHeight,
                //      el.scrollHeight);

                if( evt.webkitDirectionInvertedFromDevice ) console.log( 'onScroll', 'webkitDirectionInvertedFromDevice', evt.webkitDirectionInvertedFromDevice );
                

                const percent = 100 * el.scrollTop / (el.scrollHeight - el.clientHeight);
                let root = document.documentElement;
                root.style.setProperty("--scroll-percent", percent + '%');
                this.$store.commit( 'updatePercent', percent );

                if( this.trackScroll.start && percent >= 5 ){
                    this.trackScroll.start = false;// only once per year
                    this.track('app', 'scrolled', 'start', this.$store.getters.year, false );
                }else if( this.trackScroll.half && percent >= 50 ){
                    this.trackScroll.half = false;// only once per year
                    this.track('app', 'scrolled', 'half', this.$store.getters.year, false );
                }else if ( this.trackScroll.end && percent >= 95 ){
                    this.trackScroll.end = false;// only once per year
                    this.track('app', 'scrolled', 'end', this.$store.getters.year, false );
                }

                // const percentBuffer = 5;
                // Object.keys(this.audio).forEach(key => {
                //     if (key > percent - percentBuffer && key < percent + percentBuffer) {
                //         this.$root.$emit('AUDIO_PLAY', this.audio[key]);
                //     }
                // });
          
                if( this.anime.timeline ) this.anime.timeline.seek(this.anime.timeline.duration * (percent * 0.01));

            },

            track( action, category, label, value = 0, non_interaction = false ){
                value = (value === false || value === undefined) ? 0 : Number(value);
                // console.log('TRACK', action, category, label, value);
                gtag('event', action, {
                    'event_category': category, 
                    'event_label': label,
                    'value': value,
                    'non_interaction': non_interaction
                });
            },


        }
    };
</script>

<style lang="scss">
    @import "./assets/scss/styles";
</style>